import React, { useEffect, useState, useContext } from 'react'
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react'

import Header from '../general/header/index';
import TabBottom from '../general/tabBottom/index';
// bootstrap
import { Toast, Button, Container, Row, Col } from 'react-bootstrap';
// context
import DownloadAppContext from './../../context/downloadApp';
import isLoguedIn from './../../context/login';
// images
import logo512 from '../../images/general/nitro-logo.png'
// router
import { useNavigate } from "react-router-dom";
import Update from '../general/Update';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';

import Push from 'push.js';


const Alert = styled(Toast)`
    position: fixed;
    z-index: 10; 
    top: 0px;
    right: 0px;
    left:0;
    bottom:0;
    margin: auto;
    background: white;
`
const Child = styled.div`
   /* padding-top: 30px; */
   min-height: calc(100vh - 60px - 62px);
`


const Layout = ({ children, notHeader, notTabbottom, back, titlePage }) => {
    const { ShowAlert, setShowAlert } = useContext(DownloadAppContext);
    const { isloguedIn, setIsloguedIn } = useContext(isLoguedIn);
    const [isReadyForInstall, setIsReadyForInstall] = useState(false);

    let navigate = useNavigate();

    // useEffect(() => {
    //     if (!isloguedIn) {
    //         navigate('/login')
    //     }
    // }, [])

    useEffect(() => {
        setShowA(ShowAlert)
    }, [ShowAlert])

    const [showA, setShowA] = useState(false);
    const toggleShowA = () => setShowA(!showA);



    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (event) => {
            // Prevent the mini-infobar from appearing on mobile.
            event.preventDefault();
            console.log("👍", "beforeinstallprompt", event);
            // Stash the event so it can be triggered later.
            window.deferredPrompt = event;
            // Remove the 'hidden' class from the install button container.
            setIsReadyForInstall(true);
        });
    }, []);

    async function downloadApp() {
        console.log("👍", "butInstall-clicked");
        const promptEvent = window.deferredPrompt;
        if (!promptEvent) {
            // The deferred prompt isn't available.
            console.log("oops, no prompt event guardado en window");
            return;
        }
        // Show the install prompt.
        promptEvent.prompt();
        // Log the result
        const result = await promptEvent.userChoice;
        console.log("👍", "userChoice", result);
        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        window.deferredPrompt = null;
        // Hide the install button.
        setIsReadyForInstall(false);
    }


    useEffect(() => {
        Push.Permission.request();
    }, [])


    useEffect(() => {
        // console.log("👍", "isReadyForInstall afuera");
        if (isReadyForInstall) {
            // console.log("👍", "isReadyForInstall dentro");
            Swal.fire({
                title: 'Descargar aplicación',
                text: 'Descargue la aplicación para que pueda acceder a todas las novedades',
                imageUrl: logo512,
                imageWidth: 388 / 2,
                imageHeight: 190 / 2,
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Si, Descargar',
                imageAlt: 'Custom image',
            }).then((result) => {
                if (result.isConfirmed) {
                    downloadApp();
                }
            })
        }
    }, [isReadyForInstall])


    return (
        <>
            <Global
                styles={css`
                    html{
                        font-family: "Metropolis Regular" !important;
                    }
                    body{

                    }
                    main{
                        /* min-height: 100vh; */
                        display:flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                    .container{
                        max-width:768px !important;
                    }
                    .cursor-pointer{
                        cursor:pointer !important;
                    }
                    .slick-arrow.slick-next
                        display:none !important;
                    }
                `}
            />

            <Helmet>
                <meta charSet="utf-8" />
                <title>{titlePage ? titlePage : "NITRO+"}</title>
            </Helmet>

            <main>
                {!notHeader && <Header back={back} />}
                <Child>
                    <Update />
                    {children}
                </Child>
                {!notTabbottom && <TabBottom />}
            </main>

            {/* {isReadyForInstall &&
                <Alert show={true} onClose={toggleShowA} position={'middle-center'}>
                    <Toast.Body className='position-relative'>
                        <Container>
                            <Row className='align-items-center justify-content-between'>
                                <Col xs={8}>
                                    <div>
                                        <div className='mb-3 text-center'>
                                            <strong className='font-weight-bold text-center'>Deseas descargar la app?</strong>
                                        </div>
                                        <div className='text-center'>
                                            <Button variant='success' onClick={downloadApp} className='px-4 me-2'>Si</Button>
                                            <Button variant='danger' onClick={() => setShowAlert(false)} className='px-4'>No</Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={4} className="d-flex align-items-center justify-content-center">
                                    <div className='text-center'>
                                        <img
                                            src={logo512}
                                            className="rounded me-2"
                                            alt="icon"
                                            css={css`width: 80px; height: 80px;`}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Toast.Body>
                </Alert>
            } */}
        </>
    )
}

export default Layout