import React from 'react'
// bootstrap
import { Button, Container, Row, Col } from 'react-bootstrap'
import UserIcon from '../../../images/general/user.png'
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react'
// images
import TabUser from '../../../images/general/tabUser.png'
import TabCredi from '../../../images/general/tabCredi.png'
import TabPerfil from '../../../images/general/tabPerfil.png'
import { Link } from "react-router-dom";
// router
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'


const ContainerBottom = styled.div`
-webkit-box-shadow: 2px 5px 16px 0px #0B325E, 0px 0px 13px 0px rgba(0,0,0,0.46); 
box-shadow: 2px 5px 16px 0px #0B325E, 0px 0px 13px 0px rgba(0,0,0,0.46);
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #2E2961;
	color: white;
	text-align: center;
	max-height: 62px;
    height: 62px;
	transition: all 1s ease-in-out;
	button{
		text-decoration:none;
		width:25%;
		padding:0;
	}
	svg{
		transition: all 1s ease-in-out;
	}
`
const Text = styled.p`
	margin-bottom:0px;
	font-family: 'Metropolis Regular';
	font-size: .7rem;
	color: #E3E3E3;
	text-align: center;
`



const TabBottom = () => {

	let token = useSelector(state => state.auth.token)

	let navigate = useNavigate();
	let { pathname } = useLocation();
	// #A5D4C0

	const actionNavigate = (rute) => {
		navigate(rute)
	}


	return (
		<ContainerBottom className='py-2'>
			<Container className='d-flex justify-content-between'>
				<Button className="tab-item" variant="link" onClick={() => actionNavigate("/")}>
					<svg width={pathname === "/" ? "30" : "25"} fill={pathname === "/" ? "#A5D4C0" : "#fff"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
						<path d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.6 483.2 483.9 512 448.5 512H128.1C92.75 512 64.09 483.3 64.09 448V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5H575.8zM288 160C252.7 160 224 188.7 224 224C224 259.3 252.7 288 288 288C323.3 288 352 259.3 352 224C352 188.7 323.3 160 288 160zM256 320C211.8 320 176 355.8 176 400C176 408.8 183.2 416 192 416H384C392.8 416 400 408.8 400 400C400 355.8 364.2 320 320 320H256z" />
					</svg>
					<Text>Inicio</Text>
				</Button>
				<Button className="tab-item" variant="link" onClick={() => actionNavigate("/perfil")} disabled={token ? false : true}>
					<svg width={pathname === "/perfil" ? "30" : "25"} fill={pathname === "/perfil" ? "#A5D4C0" : "#fff"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
						<path d="M512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM176 128c35.35 0 64 28.65 64 64s-28.65 64-64 64s-64-28.65-64-64S140.7 128 176 128zM272 384h-192C71.16 384 64 376.8 64 368C64 323.8 99.82 288 144 288h64c44.18 0 80 35.82 80 80C288 376.8 280.8 384 272 384zM496 320h-128C359.2 320 352 312.8 352 304S359.2 288 368 288h128C504.8 288 512 295.2 512 304S504.8 320 496 320zM496 256h-128C359.2 256 352 248.8 352 240S359.2 224 368 224h128C504.8 224 512 231.2 512 240S504.8 256 496 256zM496 192h-128C359.2 192 352 184.8 352 176S359.2 160 368 160h128C504.8 160 512 167.2 512 176S504.8 192 496 192z" />
					</svg>
					<Text>Perfil</Text>
				</Button>
				<Button className="tab-item" variant="link" onClick={() => actionNavigate("/oportunidades")}>
					<svg width={pathname.includes('/oportunidades') ? "30" : "25"} fill={pathname.includes('/oportunidades') ? "#A5D4C0" : "#fff"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
						<path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z" />
					</svg>
					<Text>Oportunidades</Text>
				</Button>
				<Button className="tab-item" variant="link" onClick={() => actionNavigate("/creditos")}>
					<svg width={pathname === "/creditos" ? "27" : "23"} fill={pathname === "/creditos" ? "#A5D4C0" : "#fff"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
						<path d="M512 80C512 98.01 497.7 114.6 473.6 128C444.5 144.1 401.2 155.5 351.3 158.9C347.7 157.2 343.9 155.5 340.1 153.9C300.6 137.4 248.2 128 192 128C183.7 128 175.6 128.2 167.5 128.6L166.4 128C142.3 114.6 128 98.01 128 80C128 35.82 213.1 0 320 0C426 0 512 35.82 512 80V80zM160.7 161.1C170.9 160.4 181.3 160 192 160C254.2 160 309.4 172.3 344.5 191.4C369.3 204.9 384 221.7 384 240C384 243.1 383.3 247.9 381.9 251.7C377.3 264.9 364.1 277 346.9 287.3C346.9 287.3 346.9 287.3 346.9 287.3C346.8 287.3 346.6 287.4 346.5 287.5L346.5 287.5C346.2 287.7 345.9 287.8 345.6 288C310.6 307.4 254.8 320 192 320C132.4 320 79.06 308.7 43.84 290.9C41.97 289.9 40.15 288.1 38.39 288C14.28 274.6 0 258 0 240C0 205.2 53.43 175.5 128 164.6C138.5 163 149.4 161.8 160.7 161.1L160.7 161.1zM391.9 186.6C420.2 182.2 446.1 175.2 468.1 166.1C484.4 159.3 499.5 150.9 512 140.6V176C512 195.3 495.5 213.1 468.2 226.9C453.5 234.3 435.8 240.5 415.8 245.3C415.9 243.6 416 241.8 416 240C416 218.1 405.4 200.1 391.9 186.6V186.6zM384 336C384 354 369.7 370.6 345.6 384C343.8 384.1 342 385.9 340.2 386.9C304.9 404.7 251.6 416 192 416C129.2 416 73.42 403.4 38.39 384C14.28 370.6 .0003 354 .0003 336V300.6C12.45 310.9 27.62 319.3 43.93 326.1C83.44 342.6 135.8 352 192 352C248.2 352 300.6 342.6 340.1 326.1C347.9 322.9 355.4 319.2 362.5 315.2C368.6 311.8 374.3 308 379.7 304C381.2 302.9 382.6 301.7 384 300.6L384 336zM416 278.1C434.1 273.1 452.5 268.6 468.1 262.1C484.4 255.3 499.5 246.9 512 236.6V272C512 282.5 507 293 497.1 302.9C480.8 319.2 452.1 332.6 415.8 341.3C415.9 339.6 416 337.8 416 336V278.1zM192 448C248.2 448 300.6 438.6 340.1 422.1C356.4 415.3 371.5 406.9 384 396.6V432C384 476.2 298 512 192 512C85.96 512 .0003 476.2 .0003 432V396.6C12.45 406.9 27.62 415.3 43.93 422.1C83.44 438.6 135.8 448 192 448z" />
					</svg>
					<Text>Créditos</Text>
				</Button>
			</Container>
		</ContainerBottom>
	)
}

export default TabBottom