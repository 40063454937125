import { BrowserRouter, Routes, Route } from "react-router-dom";
//-----
import { Provider, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/state/createStore';

// pages
import Login from './components/pages/login/index'
import Register from './components/pages/register/index'
import LostPassword from './components/pages/lostPassword/Email'
import NewPassword from './components/pages/lostPassword/NewPassword'
import Home from './components/pages/home/index'
import Profile from './components/pages/profile/index';
import Opportunities from './components/pages/opportunities/index';
import OpportunitiesSingle from './components/pages/opportunities/Single';
import OpportunitiesSingleCategory from './components/pages/opportunities/Category';
import Credits from './components/pages/credits/index';
import Messages from './components/pages/messages';
import NotFound from './components/pages/notfound/index'
// context
import { ShowAlerDownloadAppProvider } from './context/downloadApp';
import { IsLoguedInProvider } from './context/login';
import { SocketProvider } from './context/SocketContext';
import { ChatProvider } from './context/chat/ChatContext';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ChatProvider>
					<SocketProvider>
						<ShowAlerDownloadAppProvider>
							<IsLoguedInProvider>
								<BrowserRouter>
									<Routes>
										<Route path="/" element={<Home />} />
										<Route path="login" element={<Login />} />
										<Route path="registro" element={<Register />} />
										<Route path="recuperar-contrasena" element={<LostPassword />} />
										<Route path="nueva-contrasena/:id" element={<NewPassword />} />
										<Route path="perfil" element={<Profile />} />
										<Route path="oportunidades" element={<Opportunities />} />
										<Route path="oportunidades/:oportunidad" element={<OpportunitiesSingle />} />
										<Route path="oportunidades/:oportunidad/:categoria" element={<OpportunitiesSingleCategory />} />
										<Route path="creditos" element={<Credits />} />
										<Route path="perfil/mensajes" element={<Messages />} />
										<Route path="*" element={<NotFound />} />
									</Routes>
								</BrowserRouter>
							</IsLoguedInProvider>
						</ShowAlerDownloadAppProvider>
					</SocketProvider>
				</ChatProvider>
			</PersistGate >
		</Provider >
	)
}
export default App;