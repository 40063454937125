import React, { useEffect } from 'react'
// bootstrap
import { Container, Table, Row, Col } from 'react-bootstrap'
// layout
import Layout from '../../layout/index';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { WhatsApp } from '../../general/Buttons/Whatsapp';
// images 
import imgCredits from '../../../images/credit/credito-8-7-2024.png'
import { useNavigate } from 'react-router-dom';
import clienteAxios from '../../../config/Axios';
import { useSelector } from 'react-redux';
const Title = styled.h1`
    font-family: 'Metropolis Extra Light';
    font-style: normal;
    font-size: 1.4rem;
    color: #4D4D4D;
    span{   
        font-family: 'Metropolis Medium' !important; 
    }
`
const Total = styled.p`
    font-family: 'Metropolis Extra Light';
    font-size: 1.3rem;
    color: #4D4D4D;
    text-align: right;
    span{
        font-family: 'Metropolis Medium' !important;
    }
`
const ContainerTable = styled.div`
    thead tr th{
        @media(max-width: 576px){
            font-size: .8rem;
        }
    }
    tbody tr td{
        @media(max-width: 576px){
            font-size: .8rem;
        }
    }
`
const Description = styled.p`

        font-family: ${props => props.font ? props.font : 'Metropolis Extra Light'};

        color: ${props => props.color ? props.color : '#4D4D4D'};

    thead tr th{
        @media(max-width: 576px){
            font-size: .8rem;
        }
    }
    tbody tr td{
        @media(max-width: 576px){
            font-size: .8rem;
        }
    }
`
const ContainerGeneral = styled.div`
      padding: 0 0 7rem 0;
`

const Credits = () => {

    let token = useSelector(state => state.auth.token)

    const navigate = useNavigate();


    useEffect(() => {

        if (token) {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }


            clienteAxios.post('/api/credit-visits/new-credit-visit', {}, config)
                .then(res => {
                    console.log(res.data)
                })
                .catch(err => {
                    console.log(err)
                })
        }

        
    }, [])


    return (
        <Layout back titlePage={`Crédito | NITRO+`}>
            <ContainerGeneral>
                <Container>
                    <Row>
                        <Col className="mb-2">
                            <Title className='mt-4'><span>CRÉDITOS</span></Title>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <ContainerTable>
                        <img src={imgCredits} alt="credits" className='w-100' />
                    </ContainerTable>
                    <Row className='mt-5 justify-content-center'>
                        <Col xs={12} className="d-flex justify-content-center">
                            <a href='https://api.whatsapp.com/send?phone=5493874111100&text=Solicitud de crédito desde la App de NITRO' target="_blank" css={css`text-decoration:none;`}>
                                <WhatsApp bg="#0DC142" className='d-flex' size="1rem">
                                    <span className='me-3'>
                                        Pedí el Tuyo  | WhatsApp
                                    </span>
                                    <span>
                                        <svg width={"20"} css={css`fill:#fff;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
                                    </span>
                                </WhatsApp>
                            </a>

                        </Col>
                        <Col xs={12} className="text-center mt-3">
                            <small><b>Sujeto a aprobación crediticia NITRO<sup>+</sup></b></small>
                        </Col>
                    </Row>
                </Container>
            </ContainerGeneral>
        </Layout>
    )
}

export default Credits