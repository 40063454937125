import React, { useState } from 'react';
import { withServiceWorkerUpdater } from '@3m1/service-worker-updater';
// bootstrap
import { Toast, Button, Container, Row, Col } from 'react-bootstrap';
// images
import logo512 from '../../../images/general/logo-nitro-512.png'
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'


const Alert = styled(Toast)`
    position: fixed;
    z-index: 10;
    bottom: 10px;
    right: 10px;
    background: white;
`

const Updater = (props) => {

    const [show, setShow] = useState(true);


    const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props;
    return newServiceWorkerDetected ? (
        <>
            {/* New version detected.
            <button onClick={onLoadNewServiceWorkerAccept}>Update!</button> */}
            <Alert position={'middle-center'} show={show}>
                <Toast.Body className='position-relative'>
                    <Container>
                        <Row className='align-items-center justify-content-between'>
                            <Col xs={8}>
                                <div>
                                    <div className='mb-3 text-center'>
                                        <strong className='font-weight-bold text-center'>Deseas Actualizar la app?</strong>
                                    </div>
                                    <div className='text-center'>
                                        <Button variant='success' onClick={onLoadNewServiceWorkerAccept} className='px-4 me-2'>Si</Button>
                                        <Button variant='danger' onClick={() => setShow(false)} className='px-4'>No</Button>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={4} className="d-flex align-items-center justify-content-center">
                                <div className='text-center'>
                                    <img
                                        src={logo512}
                                        className="rounded me-2"
                                        alt="icon"
                                        css={css`width: 80px; height: 80px;`}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Toast.Body>
            </Alert>
        </>
    ) : null; // If no update is available, render nothing
}

export default withServiceWorkerUpdater(Updater);