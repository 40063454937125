import React, { useEffect, useContext } from 'react';
// bootstrap
import { Container, Row, Col, Form } from 'react-bootstrap';
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Layout from '../../layout';
// dashboardstrcture
import clienteAxios from '../../../config/Axios';
import { useSelector } from 'react-redux';
import { ChatContext } from '../../../context/chat/ChatContext';
import { types } from '../../../types/types';
import { scrollToBotton } from '../../../helpers/scrollToBotton';
import { formatedDate } from "../../../helpers/horaMes";
import { SendMessage } from "../../general/SendMessage/SendMessage";


const MessageList = styled.div`
height:calc(100vh - 60px - 62px - 70px) !important;
    overflow-y: scroll;
    overflow-x: hidden;
    ::-webkit-scrollbar {
        width: 10px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
        background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .message-list { 
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .user {
        font-weight: bold;
        font-size: 1rem;
        margin-right: 10px;
    }

    .text {
        font-size: 1rem;
        line-height: 1;
    }

    .timestamp {
        font-size: 0.7rem;
        color: #000;
        margin-top:5px;
        font-weight: 500;
    }

    .text-time{
        background: #f1f1f1;
        margin-bottom:10px;
        max-width: 80%;
        border-radius: 10px;
        padding: 10px 10px;
        width: auto;
    }
    
`

const Message = styled.li`
    position:relative ;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0px 10px;
    margin: 5px 0px ;
    .text-time{
        span{
            position: absolute;
            z-index: 100;
            display: block;
            width: 8px;
            top: 0px;
            height: 13px;
        }
    }
    ${props => (props.sender === props.currentUser) ? css`
        border-radius: 5px;
        align-items: flex-end;
        text-align: right;
        .text-time{
            position: relative !important;
            border-top-right-radius: 0px !important;
                span.noAdmin{
                    right: -8px;
                    top: -8px;
                }
            }
        }
    ` : css`
        border-radius: 5px;
        align-items: flex-start;
        text-align: left;
        .text-time{
            position: relative !important;
            border-top-left-radius: 0px !important;
            span.admin{
                left: -8px;
                top: -8px;
            }
        }
    `}
`

const ContainerGeneral = styled.div`

    `

const Single = () => {

    let token = useSelector(state => state.auth.token);
    let userId = useSelector(state => state.auth.userId);
    let userName = useSelector(state => state.auth.firstname);

    const { chatState, dispatch } = useContext(ChatContext);

    useEffect(() => {
        clienteAxios.get(`/api/messages/obtain-messages`, {
            headers: {
                Authorization: `Bearer ${token} `
            }
        })
            .then(res => {
                dispatch({
                    type: types.loadMessages,
                    payload: res.data,
                })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    scrollToBotton('chat');
                }, 200)
            })
    }, [])

    // console.log(chatState.messages)
    // console.log('userId', userId)

    return (
        <Layout back titlePage={"Mensajes | NITRO+"}>
            <Container className='px-0' css={css`height: calc(100vh - 60px - 62px); `}>
                <div className="chat h-100">
                    <MessageList id='chat'>
                        <ul className="message-list">
                            {chatState.messages.map((message, i) => {
                                console.log(chatState.messages[i - 1]?.from, message.from)
                                return (
                                    <Message key={message.id} sender={message.from} currentUser={userId}>
                                        {chatState.messages[i - 1]?.from !== message.from ?
                                            <div className='user'>
                                                {message.from == userId ? userName : 'NITRO+ responde'}
                                            </div>
                                            : null
                                        }
                                        <div className={`text-time mb-0`}>
                                            {message.from === userId ?
                                                chatState.messages[i - 1]?.from !== message.from &&
                                                <span className='noAdmin'>
                                                    <svg viewBox="0 0 8 13" width="8" height="13" className=""><path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path><path fill="#f1f1f1" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path></svg>
                                                </span>
                                                :
                                                chatState.messages[i - 1]?.from !== message.from &&
                                                <span className='admin'>
                                                    <svg viewBox="0 0 8 13" width="8" height="13" className=""><path opacity=".13" fill="#0000000" d="M1.533 3.568 8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z" /><path fill="#f1f1f1" d="M1.533 2.568 8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z" /></svg>
                                                </span>
                                            }
                                            <p className='text' css={css`white-space: break-spaces;`}>
                                                {message.content}
                                                {message.files.length > 0 && message.files.map(file => {
                                                    return (
                                                        <div key={file._id} className="my-3">
                                                            <a href={file.url} css={css`font-size:0.9rem;`} target='_blank'>
                                                                {file.name}
                                                            </a>
                                                        </div>
                                                    )
                                                })}
                                            </p>
                                            <div className='timestamp'>
                                                {formatedDate(message.createdAt)}
                                            </div>
                                        </div>
                                    </Message>
                                )
                            })}
                        </ul>
                    </MessageList>
                    <SendMessage from={userId} />
                </div>
            </Container>
        </Layout>
    );
}
export default Single;
