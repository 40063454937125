import React, { useState, useEffect } from 'react'
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// bootstrap
import { Container, Row, Col, Form, InputGroup, Button, Spinner } from 'react-bootstrap'
// images
import User from '../../../images/login/user.png'
import ShadowBottom from '../../../images/login/shadow-bottom.png'
import UserInput from '../../../images/login/user-input.png'
import SecureInput from '../../../images/login/secure-input.png'
import { Primary } from '../../general/Buttons/Primary'
// router
import { useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout'
import clienteAxios from '../../../config/Axios'
import Swal from 'sweetalert2'


const Section = styled.div`
    background-color: #2E2961;
    min-height: 100vh;
    display: flex;
    align-items:center;
`
const Welcome = styled.p`
    font-family: 'Metropolis Light';
    font-style: normal;
    font-size: 1.5rem;
    color: #FFFFFF;
`
const Small = styled.p`
    font-family: 'Metropolis Regular';
    font-size: 1rem;
    color: #CACACA;
`
const FormContainer = styled(Form)`
    .input {
        border:none;
        border-top: 4px solid #A5D4C0 !important;
        border-right: 4px solid #A5D4C0 !important;
        border-bottom: 4px solid #A5D4C0 !important;
        box-sizing: border-box !important;
        border-radius: 0px 30px 30px 0px !important;
        background:#2E2961 ;
        color: #FFFFFF;
        ::placeholder {
            font-family: 'Metropolis Extra Light';
            font-size: 1rem;
            color: #f2f2f2;
            text-transform: uppercase;
        }
    }
    .input-group-text{
        background-color: #2E2961 !important;
        box-sizing: border-box !important;
        border:none;
        border-top: 4px solid #A5D4C0 !important;
        border-left: 4px solid #A5D4C0 !important;
        border-bottom: 4px solid #A5D4C0 !important;
        border-radius: 30px 0px 0px 30px !important;
    }
`

const NewPassword = () => {
    const { id } = useParams()
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const [formdata, setFormdata] = useState({
        password: "",
        confirmPassword: ""
    })

    const { password, confirmPassword } = formdata

    const readFormdata = (e) => {
        setFormdata({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true) {
            formData()
        }
    };

    const formData = () => {
        setLoading(true)
        if (password === confirmPassword) {
            clienteAxios.post(`/api/users/recover-password/${id}`, formdata)
                .then(res => {
                    Swal.fire({
                        title: '¡Listo!',
                        text: 'Tu contraseña ha sido cambiada',
                        icon: 'success',
                        confirmButtonText: 'Entendido'
                    })
                    setLoading(false)
                    navigate('/login')
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                    Swal.fire({
                        title: '¡Error!',
                        text: 'No se pudo cambiar la contraseña',
                        icon: 'error',
                        confirmButtonText: 'Entendido'
                    })
                })
        } else {
            setLoading(false)
            Swal.fire({
                title: '¡Error!',
                text: 'Las contraseñas no coinciden',
                icon: 'error',
                confirmButtonText: 'Entendido'
            })
        }
    }


    useEffect(() => {
        clienteAxios.post(`/api/users/verify-password-token/${id}`)
            .then(res => {
                console.log(res.data)
            })
            .catch(err => {
                console.log(err)
                Swal.fire({
                    title: 'Error',
                    text: 'El token no es válido',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                })
                navigate('/recuperar-contrasena')
            })
    }, [])



    return (
        <Layout notHeader notTabbottom titlePage={"Nueva clave | NITRO+"}>
            <Section>
                <Container css={css`max-width:768px;`}>
                    <Row>
                        <Col xs={12} className="text-center">
                            <img src={User} alt="user" />
                        </Col>
                        <Col xs={12} className="text-center mt-3">
                            <img src={ShadowBottom} alt="shadow" />
                        </Col>
                        <Col xs={12} className="text-center">
                            <Welcome className='mt-3'>¡Ingrese nueva Contraseña!</Welcome>
                        </Col>
                        <Col xs={12} className="">
                            <FormContainer noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group as={Col} md="12" className="mt-3">
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="passworduser">
                                            <img src={SecureInput} alt="user" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="password"
                                            placeholder="Contraseña"
                                            aria-describedby="passworduser"
                                            required
                                            className='input'
                                            name="password"
                                            onChange={readFormdata}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingrese su contraseña.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-3">
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="passworduser">
                                            <img src={SecureInput} alt="user" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="password"
                                            placeholder="Confirmar contraseña"
                                            aria-describedby="passworduser"
                                            required
                                            className='input'
                                            name="confirmPassword"
                                            onChange={readFormdata}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingrese nuevamente la contraseña.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-4">
                                    <Primary type='submit' variant="success" className='w-100' disabled={loading}>
                                        {loading &&
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className='me-2'
                                                />
                                                <span className="visually-hidden">Loading...</span>
                                            </>
                                        }Enviar</Primary>
                                </Form.Group>
                            </FormContainer>
                        </Col>
                    </Row>
                </Container>
            </Section >
        </Layout>
    )
}

export default NewPassword;