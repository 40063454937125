import {
    CHANGE_PASSWORD_START,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
} from './ActionsTypes';
// alerts
import Swal from 'sweetalert2';
// axios
import clienteAxios from '../../config/Axios';

export function ChangePasswordAction(formdata, navigate, token) {
    return (dispatch) => {
        dispatch(ChangePasswordStart())

        clienteAxios.put('/api/users/change-password', formdata, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                console.log(res.data);
                dispatch(ChangePasswordSuccess())
                Swal.fire({ icon: 'success', title: 'Todo bien', text: 'Se ha actualizado correctamente.' })
            })
            .catch(error => {
                dispatch(ChangePasswordFail())
                if (!error.response) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error de conexión.' })
                } else if (error.response.status === 400) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error en los datos' })
                } else {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error, intente de nuevo' })
                }
            })
    }
}




export const ChangePasswordStart = () => {
    return {
        type: CHANGE_PASSWORD_START
    };
};

export const ChangePasswordSuccess = () => ({
    type: CHANGE_PASSWORD_SUCCESS
})

export const ChangePasswordFail = () => ({
    type: CHANGE_PASSWORD_FAIL
})
