import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react'
// bootstrap
import { Container, Row, Col, Badge, Placeholder } from 'react-bootstrap'
// botom
import { Primary } from '../../general/Buttons/Primary';
import Layout from '../../layout';
// imahes
import CircleArrow from '../../../images/opportunities/circleArrow.png'
// router
import { useNavigate, useParams } from "react-router-dom";
// metadata
import metadata from './../../../metadata/opportunities.json'
import { WhatsApp } from '../../general/Buttons/Whatsapp';
import clienteAxios from '../../../config/Axios';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const Title = styled.h1`
    font-family: 'Metropolis Semi Bold';
    font-style: normal;
    font-size: 1.4rem;
    color: #4D4D4D;
    span{   
        font-family: 'Metropolis Medium' !important; 
    }
`

const ContainerGeneral = styled.div`
    padding: 0 0 7rem 0;
`
const P = styled.p`
    font-family: 'Metropolis Regular';
    font-size: 1rem;
    color: #2E2961;
    white-space: break-spaces;
    

`
const Description = styled.p`
    font-family: 'Metropolis Regular';
    font-size: 1rem;
    color: #2E2961;
    white-space: break-spaces;
`


const Single = () => {

    const navigate = useNavigate();

    let token = useSelector(state => state.auth.token);

    let user = useSelector(state => state.auth);

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }


    let { oportunidad } = useParams();

    const [data, setData] = useState(null)
    const [currentOpportunity, setCurrentOpportunity] = useState(null)


    const redirect = (url, oportunidad) => {
        console.log(url, oportunidad)

        if (url === '#' && user.creditIDId) {
            // alerta a usuario registradp
            Swal.fire({
                title: 'Información',
                text: 'Registre su ID de crédito para poder acceder a esta oportunidad',
                icon: 'info',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#2E2961',
            })
        } else if (url === '#' && !user.creditIDId) {
            // alerta a usuario sin registradp
            AlertWhatsApp()
        } else {
            if (url.includes('https://') || url.includes('http://')) {
                typeof window !== 'undefined' && window.open(url, '_blank')
            } else {
                if (url.includes('#HospitalVirtual')) {
                    if (user.creditIDId) {
                        typeof window !== 'undefined' && window.open('https://www.hospitalvirtual.com.ar/fio', '_blank')
                    } else {
                        AlertWhatsApp()
                    }
                } else {
                    navigate(`/oportunidades/${oportunidad}${url}`)
                }
            }
        }
    }



    useEffect(() => {
        clienteAxios.get('/api/oportunities/obtain-oportunities')
            .then(res => {
                // filtro para obtener la categoria actual
                let findCurrentOpportuny = res.data.find(item => item.name.toUpperCase() === oportunidad.replaceAll("-", " ").toUpperCase())
                console.log(findCurrentOpportuny)
                setCurrentOpportunity(findCurrentOpportuny)

                //obteniento de las categorias de una oportunidad
                clienteAxios.get(`/api/categories/obtain-categories-by-oportunity/${findCurrentOpportuny._id}`, config)
                    .then(async res => {
                        console.log(res.data)
                        let category = res.data;
                        let noId = false
                        let filesAndLinksOfCategory = []
                        // se realiza un por para obtener los archivos y links de cada categoria
                        for (let i = 0; i < category.length; i++) {
                            let item = category[i];
                            await clienteAxios.get(`/api/categories/obtain-category-files-and-link/${item._id}`, config)
                                .then(res => {
                                    noId = false
                                    filesAndLinksOfCategory.push(res.data[0])
                                })
                                .catch(err => {
                                    noId = true
                                    console.log(err)
                                })
                        }

                        if (!noId) {
                            let finalData = []
                            for (let i = 0; i < category.length; i++) {
                                let item = category[i];
                                for (let j = 0; j < filesAndLinksOfCategory.length; j++) {
                                    const item2 = filesAndLinksOfCategory[j];
                                    console.log(item2)
                                    if (item._id === item2._id) {
                                        finalData.push({
                                            description: item.description,
                                            title: item.title,
                                            files: item2.files,
                                            link: item2.link
                                        })
                                    }
                                }
                            }
                            console.log(finalData)
                            setData(finalData)
                        } else {
                            setData(category)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
            .catch(err => {
                console.log(err)
            })
    }, [])


    const AlertWhatsApp = () => {
        Swal.fire({
            title: 'Notificación',
            text: "Este beneficio es exclusivo para clientes de la comunidad NITRO+ con crédito activo. Por favor, escríbanos a nuestro WhatsApp y lo guiaremos para poder acceder a todos los beneficios de  nuestro programa",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#0CC243',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Enviar WhasApp'
        }).then((result) => {
            if (result.isConfirmed) {
                //redireccionar whatsapp
                window.open(`https://api.whatsapp.com/send?phone=5493874111100&text=${currentOpportunity.name}`, '_blank')
            }
        })
    }


    useEffect(() => {
        if (currentOpportunity) {
            // se ejecuto el useEffect
            console.log('ejecuto el useEffect')
            clienteAxios.post(`/api/oportunities/new-visit/${currentOpportunity._id}`, {})
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [currentOpportunity])


    return (
        <Layout back titlePage={`Oportunidad - ${oportunidad.replaceAll('-', " ")} | NITRO+`}>
            <Global
                styles={css`
                    .swal2-html-container{
                        margin:0 !important;
                        overflow: hidden;
                    }
                    .iframe-container{
                        iframe{
                            width:100%;
                            height:90vh !important;
                            overflow: hidden;
                        }
                        height:90vh !important
                    }
                `}
            />
            <ContainerGeneral>
                <Container>
                    <Container>
                        <Row>
                            <Col className="mb-2">
                                <Title className='mt-4 text-uppercase'>{oportunidad.replaceAll('-', " ")}</Title>
                            </Col>
                        </Row>
                        {currentOpportunity ? currentOpportunity.description && (
                            <Row>
                                <Col className="mb-2">
                                    <P>{currentOpportunity.description}</P>
                                </Col>
                            </Row>
                        ) :
                            <div className='mb-4'>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder className="w-75" xs={12} />
                                </Placeholder>
                            </div>
                        }
                        {data ? data.map((item, i) => {
                            return (
                                <div key={i}>
                                    <Primary
                                        key={i}
                                        padding="5px 15px"
                                        className='w-100 d-flex justify-content-between align-items-center mb-3 text-start'
                                        onClick={() => redirect(item.link ? item.link : "#", oportunidad)}
                                    >
                                        <P className='mb-0 text-uppercase'>{item.title}</P>
                                        <div>

                                            {item.link ?
                                                item.link.includes("#Próximamente") &&
                                                <span>
                                                    <Badge pill bg={item.link === "¡Nuevo!" ? "primary" : "secondary"} className='me-2 small'>
                                                        {item.link}
                                                    </Badge>
                                                </span>
                                                :
                                                <></>}
                                            {item.link ? item.link !== "#" ?
                                                <img src={CircleArrow} alt="arrow" className='ml-2' />
                                                :
                                                <></>
                                                : ""
                                            }

                                        </div>
                                    </Primary>
                                    <Description className='mb-5'>
                                        {item.description}
                                    </Description>
                                </div>
                            )
                        }) :
                            <>
                                <Placeholder as="h2" animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder className="w-75" xs={12} />
                                </Placeholder>
                                <Placeholder as="h2" animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder className="w-75" xs={12} />
                                </Placeholder>
                            </>
                        }

                        {currentOpportunity && currentOpportunity.buttonText ? currentOpportunity.buttonText !== "" && (
                            <Row clasName="justify-content-center">
                                <Col clasName="d-flex justify-content-center">
                                    <WhatsApp className='d-flex m-auto' size="1.2rem" onClick={() => {
                                        if (user.creditIDId && user.creditIDId.isActive) {
                                            navigate('/perfil/mensajes')
                                        } else {
                                            AlertWhatsApp()
                                        }
                                    }}>
                                        <span className='me-3'>
                                            Solicitá el tuyo
                                        </span>
                                        <span>
                                            <svg className='m-auto' width={"20"} css={css`fill:#fff;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M493.6 163c-24.88-19.62-45.5-35.37-164.3-121.6C312.7 29.21 279.7 0 256.4 0H255.6C232.3 0 199.3 29.21 182.6 41.38c-118.8 86.25-139.4 101.1-164.3 121.6C6.75 172 0 186 0 200.8v263.2C0 490.5 21.49 512 48 512h416c26.51 0 48-21.49 48-47.1V200.8C512 186 505.3 172 493.6 163zM303.2 367.5C289.1 378.5 272.5 384 256 384s-33.06-5.484-47.16-16.47L64 254.9V208.5c21.16-16.59 46.48-35.66 156.4-115.5c3.18-2.328 6.891-5.187 10.98-8.353C236.9 80.44 247.8 71.97 256 66.84c8.207 5.131 19.14 13.6 24.61 17.84c4.09 3.166 7.801 6.027 11.15 8.478C400.9 172.5 426.6 191.7 448 208.5v46.32L303.2 367.5z" />
                                            </svg>
                                            {/* <svg width={"20"} css={css`fill:#fff;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg> */}
                                        </span>
                                    </WhatsApp>
                                </Col>
                            </Row>
                        ) :
                            <></>
                        }
                    </Container>
                </Container>
            </ContainerGeneral>
        </Layout>
    )
}

export default Single