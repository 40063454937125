import {
    UPDATE_ME_START,
    UPDATE_ME_SUCCESS,
    UPDATE_ME_FAIL,
} from './ActionsTypes';
// alerts
import Swal from 'sweetalert2';
// axios
import clienteAxios from '../../config/Axios';

export function UpdateMeAction(formdata, navigate, token) {
    return (dispatch) => {
        dispatch(UpdateMeStart())

        clienteAxios.put('/api/users/me', formdata, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                console.log(res.data);
                dispatch(UpdateMeSuccess())
                Swal.fire({ icon: 'success', title: 'Todo bien', text: 'Se ha actualizado correctamente.' })
            })
            .catch(error => {
                dispatch(UpdateMeFail())
                if (!error.response) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error de conexión.' })
                } else if (error.response.status === 400) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Usuario o contraseña incorrecta' })
                } else {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error, intente de nuevo' })
                }
            })
    }
}




export const UpdateMeStart = () => {
    return {
        type: UPDATE_ME_START
    };
};

export const UpdateMeSuccess = () => ({
    type: UPDATE_ME_SUCCESS
})

export const UpdateMeFail = () => ({
    type: UPDATE_ME_FAIL
})
