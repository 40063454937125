// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'


export const Primary = styled.button`
    background: #A5D4C0;
    border: 4px solid #A5D4C0;
    box-sizing: border-box;
    border-radius: 30px;
    font-family: 'Metropolis Regular';
    font-size: ${props => props.size ? props.size : "1rem"};
    color: #FFFFFF;
    border:none;
    padding:${props => props.padding ? props.padding : "7px 50px;"};
    position: relative;
    &:disabled{
        /* background-color: rgba(0, 0, 0, .12); */
        opacity: 0.5;
        box-shadow: rgba(0, 0, 0, .2) 0 0 0 0, rgba(0, 0, 0, .14) 0 0 0 0, rgba(0, 0, 0, .12) 0 0 0 0;
        color: rgba(0, 0, 0, .37);
        cursor: default;
        pointer-events: none;
    }
`