import React, { useState } from 'react'
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// bootstrap
import { Container, Row, Col, Form, InputGroup, Button, Spinner } from 'react-bootstrap'
// images
import User from '../../../images/login/user.png'
import ShadowBottom from '../../../images/login/shadow-bottom.png'
import UserInput from '../../../images/login/user-input.png'
import SecureInput from '../../../images/login/secure-input.png'
import { Primary } from '../../general/Buttons/Primary'
// layoyt
import Layout from '../../layout'
import Swal from 'sweetalert2'
import clienteAxios from '../../../config/Axios'
// navigation
import { useNavigate } from 'react-router-dom';
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { SignUpAction } from '../../../redux/actions/SignUp';


const Section = styled.div`
    background-color: #2E2961;
    min-height: 100vh;
    display: flex;
    align-items:center;
    padding: 2rem 0rem;
`
const Welcome = styled.p`
    font-family: 'Metropolis Light';
    font-style: normal;
    font-size: 1.5rem;
    color: #FFFFFF;
`
const Small = styled.p`
    font-family: 'Metropolis Regular';
    font-size: 1rem;
    color: #CACACA;
`
const FormContainer = styled(Form)`
    label{
        text-transform:uppercase;
        color:white !important;
        font-family: 'Metropolis regular';
        margin-bottom: 0px;
        margin-left: 10px;
    }
    small{
        color:white !important;
        line-height:1;
        margin-left: 10px !important;
    }
    .invalid-feedback{
        margin-left: 10px !important;
    }
    .input {
        border:none;
        /* border-top: 4px solid #A5D4C0 !important;
        border-right: 4px solid #A5D4C0 !important;
        border-bottom: 4px solid #A5D4C0 !important; */
        border: 4px solid #A5D4C0;
        box-sizing: border-box !important;
        border-radius: 30px  !important;
        /* border-radius: 0px 30px 30px 0px !important; */
        background:#2E2961 ;
        color: #FFFFFF;
        ::placeholder {
            font-family: 'Metropolis Extra Light';
            font-size: .8rem;
            color: #f2f2f2;
            text-transform: uppercase;
            opacity:0.4;
        }
    }
    .input-group-text{
        background-color: #2E2961 !important;
        box-sizing: border-box !important;
        border:none;
        border-top: 4px solid #A5D4C0 !important;
        border-left: 4px solid #A5D4C0 !important;
        border-bottom: 4px solid #A5D4C0 !important;
        border-radius: 30px 0px 0px 30px !important;
    }
`

const Register = () => {
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const SignUpFn = (formdata, navigate) => dispatch(SignUpAction(formdata, navigate));

    let loading = useSelector(state => state.signUp.loading);

    let navigate = useNavigate();

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true) {
            formData()
        }
    };

    const [formdata, setFormdata] = useState({
        firstname: "",
        lastname: "",
        dni: "",
        creditIDId: "",
        creditID: "",
        phone: "",
        password: "",
        confirmPassword: ""
    })


    const { firstname, lastname, dni, creditIDId, creditID, phone, password, confirmPassword, isActive } = formdata;

    const onRead = (e) => {
        setFormdata({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }


    const formData = () => {


        let body = {
            firstname,
            lastname,
            dni,
            phone,
            password,
            confirmPassword,
        }
        if (creditIDId !== "") {
            body.creditIDId = creditIDId
        }


        SignUpFn(body, navigate);
    }



    const validateId = (e) => {
        e.preventDefault()
        console.log(e.target.value)

        if (e.target.value !== "") {
            clienteAxios.post(`/api/credit-ids/verify-credit-id`, { code: e.target.value })
                .then(res => {
                    console.log(res.data)
                    setFormdata({
                        ...formdata,
                        creditIDId: res.data._id,
                        creditID: res.data.code
                    })
                })
                .catch(err => {
                    Swal.fire({ title: 'Error', text: err.response.data[0].msg, icon: 'error' })
                    console.log(err.response)
                    setFormdata({
                        ...formdata,
                        creditIDId: "",
                        creditID: ""
                    })
                })
        }
    }


    return (
        <Layout notHeader notTabbottom titlePage={"Registro | NITRO+"}>
            <Section>
                <Container>
                    <Row>
                        <Col xs={12} className="text-center">
                            <img src={User} alt="user" />
                        </Col>
                        <Col xs={12} className="text-center mt-3">
                            <img src={ShadowBottom} alt="shadow" />
                        </Col>
                        <Col xs={12} className="text-center">
                            <Welcome className='mt-3'>CREA TU CUENTA!</Welcome>
                        </Col>
                        <Col xs={12} className="">
                            <FormContainer noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group as={Col} md="12">
                                    <InputGroup hasValidation controlId="name" className='d-flex flex-column'>
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            type="text"
                                            aria-describedby="name"
                                            required
                                            className='input w-100'
                                            name="firstname"
                                            onChange={onRead}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingresa tu Nombre.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-3">
                                    <InputGroup hasValidation controlId="lastname" className='d-flex flex-column'>
                                        <Form.Label>Apellido</Form.Label>
                                        <Form.Control
                                            type="text"
                                            aria-describedby="lastname"
                                            required
                                            className='input w-100'
                                            name="lastname"
                                            onChange={onRead}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingresa tu Apellido.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-3">
                                    <InputGroup hasValidation controlId="phone" className='d-flex flex-column'>
                                        <Form.Label>Teléfono</Form.Label>
                                        <Form.Control
                                            type="text"
                                            aria-describedby="phone"
                                            required
                                            className='input w-100'
                                            name="phone"
                                            onChange={onRead}
                                        />
                                        <Form.Text className="text-muted">
                                        Ejemplo : +549114111100
                                        </Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingresa tu Teléfono.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-3">
                                    <InputGroup hasValidation controlId="DNI" className='d-flex flex-column'>
                                        <Form.Label>DNI</Form.Label>
                                        <Form.Control
                                            type="text"
                                            aria-describedby="dniuser"
                                            required
                                            className='input w-100'
                                            name="dni"
                                            onChange={onRead}
                                        />
                                        <Form.Text className="text-muted">
                                            Ejemplo: 11111111 sin puntos ni guiones
                                        </Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingrese su DNI.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-3">
                                    <InputGroup controlId="ID" className='d-flex flex-column'>
                                        <Form.Label>ID de Crédito <small css={css`font-size:.7rem;`}></small></Form.Label>
                                        <Form.Control
                                            type="text"
                                            className='input w-100'
                                            autoComplete={false}
                                            value={creditID}
                                            name="creditID"
                                            onBlur={validateId}
                                            onChange={onRead}
                                        />
                                        <Form.Text className="text-muted">
                                            Si no es cliente aún, puede registrarse sin ID y acceder a algunas secciones de la app. Siendo cliente, podrá acceder a todos los beneficios exclusivos para nuestra comunidad
                                        </Form.Text>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-3">
                                    <InputGroup hasValidation controlId="password" className='d-flex flex-column'>
                                        <Form.Label>Contraseña</Form.Label>
                                        <Form.Control
                                            type="password"
                                            aria-describedby="passworduser"
                                            required
                                            className='input w-100'
                                            name="password"
                                            onChange={onRead}
                                        />
                                          <Form.Text className="text-muted">
                                            Debe ser mayor a 4 caracteres
                                        </Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingrese su contraseña.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-3">
                                    <InputGroup hasValidation controlId="passworagainduser" className='d-flex flex-column'>
                                        <Form.Label>Confirmar contraseña</Form.Label>
                                        <Form.Control
                                            type="password"
                                            aria-describedby="passworagainduser"
                                            required
                                            className='input w-100'
                                            name="confirmPassword"
                                            onChange={onRead}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingrese nuevamente su contraseña.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-4">
                                    <Primary type='submit' variant="success" className='w-100' disabled={loading}>
                                        {loading &&
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className='me-2'
                                                />
                                                <span className="visually-hidden">Loading...</span>
                                            </>
                                        }
                                        Crear cuenta
                                    </Primary>
                                </Form.Group>
                            </FormContainer>
                            <Form.Group as={Col} md="12" className="mt-2">
                                <Button variant="link" className='w-100 text-white cursor-pointer' onClick={() => navigate('/login')}>Iniciar Sesión</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Section >
        </Layout>
    )
}

export default Register;