// store.js
import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist' // imports from redux-persist
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import rootReducer from '../reducers/index'; // Root reducer

const initialState = {};
const middleware = [thunk];

const persistConfig = { // configuration object for redux-persist
    // key: ['auth'],
    key: ['auth'],
    storage, // define which storage to useh
}

const persistedReducer = persistReducer(persistConfig, rootReducer, initialState) // create a persisted reducer

const store = createStore(
    persistedReducer, // pass the persisted reducer instead of rootReducer to createStore
    // compose(applyMiddleware(...middleware), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) // add any middlewares here
    compose(applyMiddleware(...middleware))
)

const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export { store, persistor }