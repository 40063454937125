import {
    SIGN_UP_START,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAIL,

    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
} from './ActionsTypes';
// alerts
import Swal from 'sweetalert2';
// axios
import clienteAxios from '../../config/Axios';

export function SignUpAction(formdata, navigate) {
    return (dispatch) => {
        dispatch(SignUpStart())

        clienteAxios.post('/api/users/signup', formdata)
            .then((res) => {
                // console.log(res.data)
                dispatch(SignUpSuccess())
                dispatch(AuthSuccess(res.data))
                navigate('/')
            })
            .catch(error => {
                console.log(error)
                dispatch(SignUpFail())

                if (!error.response) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Error de conexión.' })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        html: error.response.data.map(item => ("<b>Error: </b>" + item.msg + '<br>'))
                        // html: "<b>Error: </b>" + error.response.data.err
                    })
                }
            })
    }
}



export const SignUpStart = () => {
    return {
        type: SIGN_UP_START
    };
};

export const SignUpSuccess = () => ({
    type: SIGN_UP_SUCCESS
})

export const SignUpFail = () => ({
    type: SIGN_UP_FAIL,
})

export const AuthSuccess = (auth) => ({
    type: AUTH_SUCCESS,
    payload: auth
})
