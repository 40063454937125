import React, { useState } from 'react'
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// bootstrap
import { Container, Row, Col, Form, InputGroup, Spinner } from 'react-bootstrap'
// images
import User from '../../../images/login/user.png'
import ShadowBottom from '../../../images/login/shadow-bottom.png'
import UserInput from '../../../images/login/user-input.png'
import { Primary } from '../../general/Buttons/Primary'
// router
import { useNavigate } from "react-router-dom";
import Layout from '../../layout'
import Swal from 'sweetalert2'
import clienteAxios from '../../../config/Axios'


const Section = styled.div`
    background-color: #2E2961;
    min-height: 100vh;
    display: flex;
    align-items:center;
`
const Welcome = styled.p`
    font-family: 'Metropolis Light';
    font-style: normal;
    font-size: 1.5rem;
    color: #FFFFFF;
`
const FormContainer = styled(Form)`
    .input {
        border:none;
        border-top: 4px solid #A5D4C0 !important;
        border-right: 4px solid #A5D4C0 !important;
        border-bottom: 4px solid #A5D4C0 !important;
        box-sizing: border-box !important;
        border-radius: 0px 30px 30px 0px !important;
        background:#2E2961 ;
        color: #FFFFFF;
        ::placeholder {
            font-family: 'Metropolis Extra Light';
            font-size: 1rem;
            color: #f2f2f2;
            text-transform: uppercase;
        }
    }
    .input-group-text{
        background-color: #2E2961 !important;
        box-sizing: border-box !important;
        border:none;
        border-top: 4px solid #A5D4C0 !important;
        border-left: 4px solid #A5D4C0 !important;
        border-bottom: 4px solid #A5D4C0 !important;
        border-radius: 30px 0px 0px 30px !important;
    }
`

const Email = () => {

    const [loading, setloading] = useState(false)

    let navigate = useNavigate();


    const [dni, setDni] = useState("");
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true) {
            formData()
        }
    };

    const formData = () => {
        setloading(true)

        let body = {
            dni: dni
        }

        clienteAxios.post('/api/users/password-recovery-request', body)
            .then(res => {
                setloading(false)
                Swal.fire({
                    title: '¡Enviado!',
                    text: 'Se ha enviado un mensaje con un enlace para restablecer su contraseña',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                })
            })
            .catch(err => {
                setloading(false)
                Swal.fire({
                    title: '¡Error!',
                    text: 'No se ha podido enviar el mensaje, intente nuevamente',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                })
            })
    }


    return (
        <Layout notHeader notTabbottom titlePage={"Recuperar clave | +"}>
            <Section>
                <Container css={css`max-width:768px;`}>
                    <Row>
                        <Col xs={12} className="text-center">
                            <img src={User} alt="user" />
                        </Col>
                        <Col xs={12} className="text-center mt-3">
                            <img src={ShadowBottom} alt="shadow" />
                        </Col>
                        <Col xs={12} className="text-center">
                            <Welcome className='mt-3'>Ingrese su DNI para recuperar contraseña!</Welcome>
                            {/* <small className='text-center'>Enviaremos un mensaje al telefono registrado a la cuenta</small> */}
                        </Col>
                        <Col xs={12} className="">
                            <FormContainer noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group as={Col} md="12">
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="dniUser">
                                            <img src={UserInput} alt="user" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="DNI"
                                            aria-describedby="dniUser"
                                            required
                                            className='input'
                                            onChange={(e) => setDni(e.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingresa su DNI
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-4">
                                    <Primary type='submit' variant="success" className='w-100' disabled={loading}>
                                        {loading &&
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className='me-2'
                                                />
                                                <span className="visually-hidden">Loading...</span>
                                            </>
                                        }Enviar</Primary>
                                </Form.Group>
                            </FormContainer>
                        </Col>
                    </Row>
                </Container>
            </Section >
        </Layout>
    )
}

export default Email;