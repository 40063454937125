import React, { createContext, useState } from 'react';

const DownloadAppContext = createContext();
export default DownloadAppContext;

export function ShowAlerDownloadAppProvider({ children }) {
    const [ShowAlert, setShowAlert] = useState(true);

    return (
        <DownloadAppContext.Provider
            value={{
                ShowAlert,
                setShowAlert
            }}
        >
            {children}
        </DownloadAppContext.Provider>
    );
}


