import { types } from "../../types/types";

export const chatReducer = (state, action) => { //reducer--> funcion pura que no debe tener interacciones del exterior
  switch (action.type) {
    case types.cerrarSesion:
      return {
        // uid: '',
        // chatActivo: null,
        // usuarios: [],
        messages: [],
      }
    // case types.usuariosCargados:
    //   return {
    //     ...state,
    //     usuarios: [...action.payload]
    //   }
    // case types.activarChat:
    //   if (state.chatActivo === action.payload) return state
    //   return {
    //     ...state,
    //     chatActivo: action.payload,
    //     mensajes: []
    //   }
    case types.newMessage:
      // if (state.chatActivo === action.payload.de
      //   || state.chatActivo === action.payload.para
      // ) 
      // {
      return {
        ...state,
        messages: [...state.messages, action.payload]
      }
    // } else {
    //   return state
    // }

    case types.loadMessages:
      return {
        ...state,
        messages: [...action.payload]
      }

    case types.cleanMessages:
      return {
        ...state,
        messages: []
      }
    default:
      return state;
  }
}