import React from 'react'
import Slider from "react-slick";
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// bootstrap
import { Container, Row, Col, Badge, Placeholder } from 'react-bootstrap'
// botom
import { Primary } from '../../general/Buttons/Primary';
import Layout from '../../layout';
// imahes
import CircleArrow from '../../../images/opportunities/circleArrow.png'
// router
import { useNavigate } from "react-router-dom";
import { Contanos } from '../../general/Buttons/Contanos';
import clienteAxios from '../../../config/Axios';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const Title = styled.h1`
    font-family: 'Metropolis Extra Light';
    font-style: normal;
    font-size: 1.4rem;
    color: #4D4D4D;
    span{   
        font-family: 'Metropolis Medium' !important; 
    }
`

const ContainerGeneral = styled.div`
    /* padding: 0 0 3rem 0; */
    /* height: 90vh; */
`
const P = styled.p`
    font-family: 'Metropolis Extra Light';
    font-size: ${props => props.size ? props.size : ".9rem"};
    color: ${props => props.color ? props.color : "#2E2961"};
    line-height: 1;
`
const Text = styled.p`
    font-family: 'Metropolis Extra Light';
    font-size: .8rem;
    color: #2E2961;
`

const Opportunities = () => {

    let creditId = useSelector(state => state.auth.creditIDId);

    const navigate = useNavigate();

    const [opportunities, setOpportunities] = React.useState(null)


    React.useEffect(() => {
        clienteAxios.get('/api/oportunities/obtain-oportunities')
            .then(res => {
                console.log(res.data)
                setOpportunities(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const AlertWhatsApp = () => {
        Swal.fire({
            title: 'Notificación',
            text: "Escribenos al Whatsapp y contanos que otros beneficios te intereran",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#0CC243',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Enviar WhasApp'
        }).then((result) => {
            if (result.isConfirmed) {
                //redireccionar whatsapp
                window.open(`https://api.whatsapp.com/send?phone=5493874111100`, '_blank')
            }
        })
    }


    return (
        <Layout back titlePage={"Oportunidades | NITRO+"}>
            <ContainerGeneral>
                <Container className='h-100'>
                    <div css={css`min-height: 67px;`}>
                        <Container>
                            <Row>
                                <Col className="mb-2">
                                    <Title className='mt-4'><span>OPORTUNIDADES</span></Title>
                                    {/* <Text>(beneficios exclusivos para clientes de la comunidad NITRO+ Microcréditos):</Text> */}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className='d-flex flex-column justify-content-between' css={css`min-height: calc(100vh - 67px - 60px - 62px);`}>
                        <div>
                            {opportunities ? opportunities.map((item, index) => {
                                return (
                                    <Primary key={index} padding="3px 15px" onClick={() => {
                                        item.status !== "proximamente" && navigate(`/oportunidades/${item.name.replace(/\s+/g, '-').toLowerCase()}`)
                                    }} className='w-100 d-flex justify-content-between align-items-center mb-3 text-start'>
                                        <P className='mb-0 text-uppercase'>{item.name}</P>
                                        <div>
                                            {item.status
                                                ?
                                                <span className={item.status === "nuevo" ? "" : "small"} css={css`span{
                                                    ${item.status === "nuevo" ? "" : "background-color: rgb(0 0 0 / 13%) !important;"}
                                                }`}>
                                                    <Badge pill bg={item.status === "nuevo" ? "primary" : "secondary"} className='me-2 small'>
                                                        {item.status === "activo"
                                                            ? ""
                                                            : item.status === "nuevo"
                                                                ? "¡Nuevo!"
                                                                : item.status
                                                        }
                                                    </Badge>
                                                </span>
                                                : null}
                                            <img src={CircleArrow} alt="arrow" className='ml-2' width={30} />
                                        </div>
                                    </Primary>
                                )
                            }) :
                                <>
                                    <Placeholder as="h2" animation="glow">
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                    <Placeholder as="h2" animation="glow">
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                    <Placeholder as="h2" animation="glow">
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                    <Placeholder as="h2" animation="glow">
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                    <Placeholder as="h2" animation="glow">
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                    <Placeholder as="h2" animation="glow">
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                </>
                            }
                        </div>
                        <div>
                            <Contanos padding="3px 15px" className='w-100 d-flex justify-content-between align-items-center mb-3 text-start'
                                // disabled={creditId && creditId.isActive ? false : true}
                                onClick={() => {
                                    creditId && creditId.isActive ? navigate(`/perfil/mensajes`) : AlertWhatsApp()

                                }}
                            >
                                <P size=".7rem" color="#fff" className='mb-0 text-uppercase'>¿Te interesan otras? ¿Cuáles? Contanos</P>
                                <div>
                                    <img src={CircleArrow} alt="arrow" className='ml-2' width={30} />
                                </div>
                            </Contanos>
                        </div>
                    </div>
                </Container>
            </ContainerGeneral>
        </Layout>
    )
}

export default Opportunities