import { UPDATE_ME_START, UPDATE_ME_SUCCESS, UPDATE_ME_FAIL } from '../actions/ActionsTypes';

const initialState = {
    success: false,
    error: null,
    loading: false,
};


export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_ME_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case UPDATE_ME_SUCCESS:
            return {
                ...state,
                success: true,
                error: null,
                loading: false
            }
        case UPDATE_ME_FAIL:
            return {
                ...state,
                success: false,
                error: true,
                loading: false,
            }
        default:
            return state;
    }
}