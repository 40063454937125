import {
    AUTH_START, AUTH_SUCCESS, AUTH_FAIL,
    LOGOUT_START,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
} from '../actions/ActionsTypes';

const initialState = {
    success: false,
    token: null,

    userId: null,
    creditIDId: null,
    firstname: null,
    lastname: null,
    phone: null,
    dni: null,
    role: null,

    error: null,
    loading: false,
};


export default function (state = initialState, action) {
    switch (action.type) {
        case AUTH_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case AUTH_SUCCESS:
            return {
                ...state,
                success: true,
                token: action.payload.token,
                userId: action.payload.user._id,
                creditIDId: action.payload.user.creditIDId,
                firstname: action.payload.user.firstname,
                lastname: action.payload.user.lastname,
                phone: action.payload.user.phone,
                dni: action.payload.user.dni,
                role: action.payload.user.roleId,
                error: null,
                loading: false
            }
        case AUTH_FAIL:
            return {
                ...state,
                success: false,
                token: null,
                userId: null,
                firstname: null,
                lastname: null,
                phone: null,
                dni: null,
                role: null,
                error: true,
                loading: false,
            }
        case LOGOUT_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                success: true,
                token: null,
                userId: null,
                creditIDId: null,
                firstname: null,
                lastname: null,
                phone: null,
                dni: null,
                role: null,
                error: null,
                loading: false
            }
        case LOGOUT_FAIL:
            return {
                ...state,
                success: false,
                token: null,
                userId: null,
                creditIDId: null,
                firstname: null,
                lastname: null,
                phone: null,
                dni: null,
                role: null,
                error: true,
                loading: false,
            }
        default:
            return state;
    }
}