import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// bootstrap
import { Container, Row, Col } from 'react-bootstrap'
// botom
import { Primary } from '../../general/Buttons/Primary';
// images
import LogoNitro from '../../../images/general/nitro-logo.png'
import Slider2 from '../../../images/home/slider2.png'
import Slider3 from '../../../images/home/slider3.png'
import Slider4 from '../../../images/home/slider4.png'
import Slider5 from '../../../images/home/slider5.png'
// layout
import Layout from '../../layout/index'
import { Link } from 'react-router-dom';

import clienteAxios from '../../../config/Axios';

const Card = styled.div`
    background: #F7F7F7;
    ${props => props.bg && css`
        background-image:
        linear-gradient(to bottom, transparent, rgba(0, 0, 0, .35)),
        url(${props.bg});
        background-size: cover;
        color: white;
        background-position: center;
    `}
    border-radius: 27px;
    min-height: 300px;
    padding:15px;
    margin:0px 10px;
    min-height:420px;
`
const Title = styled.h1`
    font-family: 'Metropolis Extra Light';
    font-style: normal;
    font-size: 1.4rem;
    color: #4D4D4D;
    span{   
        font-family: 'Metropolis Medium' !important; 
    }
`
const TitleCard = styled.p`
    font-family: ${props => props.font ? props.font : 'Metropolis Semi Bold'} ;
    font-size: ${props => props.size ? props.size : '1.2rem'};
    color: ${props => props.dark ? "#4D4D4D" : "white"} ;
    line-height:1;
`
const Text = styled.p`
    font-family: 'Metropolis Regular';
    font-size: 1rem;
    color: ${props => props.dark ? "#4D4D4D" : "white"} ;
    line-height:1;
`

const ContainerGeneral = styled.div`
    .slick-arrow{
        display:none !important ;
    }
    /* .slick-list{
        padding:0 20px !important;
    }
    .slick-track{
        width:100% !important;
    }
    .slick-initialized .slick-slide{
        width:100% !important;
    } */
`

const Section1 = () => {

    const [sliders, setSliders] = useState([])


    useEffect(() => {
        clienteAxios.get('/api/sliders/obtain-sliders')
            .then(res => {
                console.log(res.data)
                setSliders(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])


    const settings = {
        className: "center",
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        speed: 1500,
        dots: true,
        autoplay: true,
        
    };

    return (
        <Layout titlePage="Inicio | NITRO+">
            <ContainerGeneral className='pt-4'>
                <Container className='px-0'>
                    <Slider {...settings} pauseOnHover={true}>
                        <div>
                            <Card className='d-flex flex-column justify-content-end text-center'>
                                <img src={LogoNitro} alt="nitro logo" className='img-fluid mt-auto mb-5' />
                                <TitleCard dark className='mt-3 mb-0'> Potenciamos tu Negocio</TitleCard>
                                <Text dark className='mt-1'>
                                    Financiamiento Responsable, Asesoramientos y Oportunidades a tu medida.
                                </Text>
                            </Card>
                        </div>
                        {sliders.map((slider, i) => (
                            <div>
                                <Card key={i} bg={slider.imageData.url} className='d-flex flex-column justify-content-end'>
                                    <TitleCard className='mt-3 mb-0'>{slider.title}</TitleCard>
                                    <Text className='my-2 mb-0'>{slider.subtitle}</Text>
                                    <Text className='mt-1'>
                                        {slider.content}
                                    </Text>
                                    <Link to={`${slider.oportunityId ? '/oportunidades/' + slider.oportunityId.name.replaceAll(" ", "-").toLowerCase() : "/creditos"}`}>
                                        <Primary size="1rem" className='me-auto' padding="7px 40px">Ver más</Primary>
                                    </Link>
                                </Card>
                            </div>
                        ))}
                    </Slider>
                </Container>
            </ContainerGeneral>
        </Layout>
    )
}

export default Section1