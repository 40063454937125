import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createContext } from 'react';
import { useSocket } from '../hooks/useSocket'
import { ChatContext } from './chat/ChatContext';
import { types } from '../types/types';
import { scrollToBottonAnimated } from '../helpers/scrollToBotton';
import Push from 'push.js';
import logo512 from '../images/general/logo-nitro-512.png'
import { useNavigate } from 'react-router-dom';
// import { types } from '../types/types';
// import { scrollToBottonAnimated } from '../helpers/scrollToBotton';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {

	// let navigate = useNavigate()

	const { socket, online, connectSocket, disconnectSocket } = useSocket('https://fio-dev.herokuapp.com');
	// const { socket, online, connectSocket, disconnectSocket } = useSocket('http://localhost:8080');

	const auth = useSelector(state => state.auth)
	const { dispatch } = useContext(ChatContext);

	useEffect(() => {
		if (auth.token) {
			connectSocket(auth);
		}
	}, [auth, connectSocket])

	useEffect(() => {
		if (!auth.token) {
			disconnectSocket();
		}
	}, [auth, disconnectSocket])

	// //Escuchar los cambios en los usuarios conectados
	// useEffect(() => {
	//   socket?.on('lista-usuarios', (usuarios) => {
	//     dispatch({
	//       type: types.usuariosCargados,
	//       payload: usuarios
	//     })
	//   })
	// }, [socket, dispatch])


	useEffect(() => {
		const handleNewMessage = (message) => {

			if (message.from !== auth.userId) {

				Push.create("Nuevo mensaje NITRO+", {
					body: message.content,
					icon: logo512,
					// timeout: 10000,
					onClick: function () {
						window.focus();
						// navigate(`mensajes/usuario/${message.from}`);
						this.close();
					}
				});

			}

			dispatch({
				type: types.newMessage,
				payload: message
			})

			setTimeout(() => {
				scrollToBottonAnimated('chat');
			}, 200)
		}

		const handleNewNotification = (notification) => {
			console.log(notification)

			Push.create(notification.subject, {
				body: notification.message,
				icon: logo512,
				// timeout: 10000,
				onClick: function () {
					window.focus();
					// navigate(`mensajes/usuario/${message.from}`);
					this.close();
				}
			});

		}

		socket?.on('newMessage', (message) => handleNewMessage(message))
		socket?.on('pushNotification', (message) => handleNewNotification(message))

		return function cleanup() {
			// socket?.off('newMessage', (message) => handleNewMessage(message))
			socket?.removeAllListeners("newMessage");
		};
	}, [socket, dispatch])

	return (
		<SocketContext.Provider value={{ socket, online }}>
			{children}
		</SocketContext.Provider>
	)
}