// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'


export const WhatsApp = styled.button`
    background-color: ${props => props.bg ? props.bg : '#4D4D4D'};
    border-radius: 100px;
    box-shadow: rgba(46, 41, 97, .2) 0 -25px 18px -14px inset,rgba(46, 41, 97, .15) 0 1px 2px,rgba(46, 41, 97, .15) 0 2px 4px,rgba(46, 41, 97, .15) 0 4px 8px,rgba(46, 41, 97, .15) 0 8px 16px,rgba(46, 41, 97, .15) 0 16px 32px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
    font-weight: bold;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: ${props => props.size ? props.size : '1rem'};
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    &:hover {
        /* box-shadow: rgba(46, 41, 97,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px; */
        transform: scale(1.05) rotate(-1deg);
    }
`