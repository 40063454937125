import { combineReducers } from 'redux';
import authReducer from './Auth';
import signUpReducer from './SignUp';
import updateMeReducer from './UpdateMe';
import changePasswordReducer from './ChangePassword';

export default combineReducers({
    auth: authReducer,
    signUp: signUpReducer,
    updateMe: updateMeReducer,
    changePassword: changePasswordReducer,
});