import React, { useState, useContext } from 'react'
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// layout
import Layout from '../../layout'
// bootstrap
import { Container, Row, Col, Form, InputGroup, Button, Spinner } from 'react-bootstrap'
// images
import User from '../../../images/login/user.png'
import ShadowBottom from '../../../images/login/shadow-bottom.png'
import UserInput from '../../../images/login/user-input.png'
import SecureInput from '../../../images/login/secure-input.png'
import { Primary } from '../../general/Buttons/Primary'
// navigation
import { useNavigate } from 'react-router-dom';
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { LoginAction } from '../../../redux/actions/Auth';
// context
import isLoguedIn from '../../../context/login';

const Section = styled.div`
    background-color: #2E2961;
    min-height: 100vh;
    display: flex;
    align-items:center;
`
const Welcome = styled.p`
    font-family: 'Metropolis Light';
    font-style: normal;
    font-size: 1.5rem;
    color: #FFFFFF;
`
const Small = styled.p`
    font-family: 'Metropolis Regular';
    font-size: 1rem;
    color: #CACACA;
    margin-left: 10px !important;
`
const FormContainer = styled(Form)`
    .was-validated .input-group-text:invalid{
        border-color: #dc3545 !important;
    }
    small{
        color:white !important;
        line-height:1;
        margin-left: 10px !important;
    }
    .invalid-feedback{
        margin-left: 10px !important;
    }
    .input {
        border:none;
        border-top: 4px solid #A5D4C0;
        border-right: 4px solid #A5D4C0;
        border-bottom: 4px solid #A5D4C0;
        box-sizing: border-box !important;
        border-radius: 0px 30px 30px 0px !important;
        background:#2E2961 ;
        color: #FFFFFF;
        ::placeholder {
            font-family: 'Metropolis Extra Light';
            font-size: 1rem;
            color: #f2f2f2;
            text-transform: uppercase;
        }
    }
    .input-group-text{
        background-color: #2E2961 !important;
        box-sizing: border-box !important;
        border:none;
        border-top: 4px solid #A5D4C0 ;
        border-left: 4px solid #A5D4C0 ;
        border-bottom: 4px solid #A5D4C0 ;
        border-radius: 30px 0px 0px 30px ;
    }
`

const Login = () => {

    const navigate = useNavigate()

    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const LoginFn = (formdata, navigate) => dispatch(LoginAction(formdata, navigate));

    let loading = useSelector(state => state.auth.loading);

    const { isloguedIn, setIsloguedIn } = useContext(isLoguedIn);
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true) {
            formData()
        }
    };

    const [formdata, setFormdata] = useState({
        dni: '',
        password: ''
    })

    const onRead = (e) => {
        setFormdata({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }


    const formData = () => {
        setIsloguedIn(true)

        LoginFn(formdata, navigate)
        console.log(formdata)
    }


    return (
        <Layout notHeader notTabbottom titlePage={"Login | NITRO+"}>
            <Section>
                <Container>
                    <Row>
                        <Col xs={12} className="text-center">
                            <img src={User} alt="user" />
                        </Col>
                        <Col xs={12} className="text-center mt-3">
                            <img src={ShadowBottom} alt="shadow" />
                        </Col>
                        <Col xs={12} className="text-center">
                            <Welcome className='mt-3'>¡Bienvenido!</Welcome>
                        </Col>
                        <Col xs={12} className="">
                            <FormContainer noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group as={Col} md="12">
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="user">
                                            <img src={UserInput} alt="user" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="DNI"
                                            aria-describedby="user"
                                            required
                                            className='input'
                                            name='dni'
                                            onChange={onRead}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingresa tu usuario.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-3">
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="passworduser">
                                            <img src={SecureInput} alt="user" />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="password"
                                            placeholder="Contraseña"
                                            aria-describedby="passworduser"
                                            required
                                            className='input'
                                            name='password'
                                            onChange={onRead}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor ingrese su contraseña.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-3">
                                    <Small className='cursor-pointer' onClick={() => navigate('/recuperar-contrasena')}>Olvidaste tu contraseña?</Small>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-4">
                                    <Primary type='submit' variant="success" className='w-100' disabled={loading}>
                                        {loading &&
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className='me-2'
                                                />
                                                <span className="visually-hidden">Loading...</span>
                                            </>
                                        }
                                        Iniciar Sesión
                                    </Primary>
                                </Form.Group>
                            </FormContainer>
                            <Form.Group as={Col} md="12" className="mt-2">
                                <Button variant="link" className='w-100 text-white cursor-pointer' onClick={() => navigate('/registro')}>Crear mi cuenta</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Section >
        </Layout>
    )
}

export default Login;