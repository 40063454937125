import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// bootstrap
import { Container, Row, Col, Badge } from 'react-bootstrap'
// botom
import { Primary } from '../../general/Buttons/Primary';
import Layout from '../../layout';
// imahes
import CircleArrow from '../../../images/opportunities/circleArrow.png'
// router
import { useNavigate, useParams } from "react-router-dom";
// metadata
import metadata from './../../../metadata/opportunities.json'
import { WhatsApp } from '../../general/Buttons/Whatsapp';
import { Doc } from '../../general/Buttons/Doc';

import IcoImg from '../../../images/opportunities/img.png'
import IcoExcel from '../../../images/opportunities/excel.png'
import IcoPdf from '../../../images/opportunities/pdf.png'
import clienteAxios from '../../../config/Axios';
import { useSelector } from 'react-redux';

const Title = styled.h1`
    font-family: 'Metropolis Semi Bold';
    font-style: normal;
    font-size: 1.4rem;
    color: #4D4D4D;
    span{   
        font-family: 'Metropolis Medium' !important; 
    }
`

const ContainerGeneral = styled.div`
    padding: 0 0 7rem 0;
`
const P = styled.p`
    font-family: 'Metropolis Regular';
    font-size: 1rem;
    color: #2E2961;
    white-space: break-spaces;
    

`
const Description = styled.p`
    font-family: 'Metropolis Regular';
    font-size: 1rem;
    color: #2E2961;
    white-space: break-spaces;
`
const ContianerItem = styled.div`
    background: #f8f8f8 !important;
    color: #333;
    background: #f8f8f8;
    color: #333;
    padding: 2rem 2.5rem;
    background: #c2fbd7;
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 10%) 0 1px 2px 0;
    cursor: pointer;
    margin-top: 10em;
    font-size: 19px;
    text-align: left;
    margin: auto;
    &:hover {
        img{
            top: 10%;
            right: -35%;
            transform: scale(1.1) rotate(-7deg);
        }
    }
    img{
        position: absolute;
        top: 36%;
        right: -5%;
        width: 150px;
        height: 150px;
        transform: rotate(-7deg);
        transition: all .3s;
        @media(max-width: 768px){
            width: 100px;
            height: 100px;
            top: 55%;
        }
    }
`


const Category = () => {

    let token = useSelector(state => state.auth.token);

    const navigate = useNavigate();
    let { categoria, oportunidad } = useParams();


    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const [data, setData] = useState(null)
    const [currentOpportunity, setCurrentOpportunity] = useState(null)


    useEffect(() => {
        clienteAxios.get('/api/oportunities/obtain-oportunities')
            .then(res => {
                // filtro para obtener la categoria actual
                let findCurrentOpportuny = res.data.find(item => item.name.toUpperCase() === oportunidad.replaceAll("-", " ").toUpperCase())
                console.log(findCurrentOpportuny)
                setCurrentOpportunity(findCurrentOpportuny)

                //obteniento de las categorias de una oportunidad
                clienteAxios.get(`/api/categories/obtain-categories-by-oportunity/${findCurrentOpportuny._id}`, config)
                    .then(async res => {
                        let category = res.data;
                        let filesAndLinksOfCategory = []
                        // se realiza un por para obtener los archivos y links de cada categoria
                        for (let i = 0; i < category.length; i++) {
                            let item = category[i];
                            await clienteAxios.get(`/api/categories/obtain-category-files-and-link/${item._id}`, config)
                                .then(res => {
                                    filesAndLinksOfCategory.push(res.data[0])
                                })
                                .catch(err => {
                                    console.log(err)
                                })
                        }

                        let finalData = []
                        for (let i = 0; i < category.length; i++) {
                            let item = category[i];
                            for (let j = 0; j < filesAndLinksOfCategory.length; j++) {
                                const item2 = filesAndLinksOfCategory[j];
                                console.log(item2)
                                if (item._id === item2._id) {
                                    finalData.push({
                                        item,
                                        files: item2.files,
                                        link: item2.link
                                    })
                                }
                            }
                        }
                        console.log(finalData)

                        let findCurrentCategory = finalData.find(item => item.link.includes(categoria))

                        console.log(findCurrentCategory)
                        setData(findCurrentCategory)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
            .catch(err => {
                console.log(err)
            })
    }, [])


    // useEffect(() => {
    //     let currentOpportunitie = []
    //     console.log(oportunidad.replaceAll("-", " "))
    //     for (let i = 0; i < metadata.length; i++) {
    //         const item = metadata[i];
    //         console.log(item)
    //         if (item.title === oportunidad.replaceAll("-", " ")) {
    //             currentOpportunitie.push(item)
    //         }
    //     }
    //     console.log(currentOpportunitie[0])

    //     let data;
    //     for (let i = 0; i < currentOpportunitie[0].description.length; i++) {
    //         const category = currentOpportunitie[0].description[i];

    //         if (category.url === categoria) {
    //             data = category
    //         }

    //     }
    //     console.log(data)
    //     setData(data)

    // }, [])

    return (
        <Layout back titlePage={`Oportunidad - ${oportunidad.replaceAll('-', " ")} - ${categoria.replaceAll('-', " ")} | NITRO+`}>
            <ContainerGeneral>
                <Container>
                    <Container>
                        <Row>
                            <Col className="mb-2">
                                <Title className='mt-4 text-uppercase'>{categoria.replaceAll('-', " ")}</Title>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <P>Documentos descargables</P>
                            </Col>
                            {data && data.files.map((item, index) => {
                                return (
                                    <Col xs={12} className="mt-3">
                                        <a href={item.url} target="_blank" css={css`text-decoration:none !important;`}>
                                            <ContianerItem>
                                                <p><strong>{item.name}</strong></p>
                                                <p className="small">Descargar</p>
                                                <img src={
                                                    item.url.includes('.png') || item.url.includes('.jpg') ?
                                                        IcoImg
                                                        :
                                                        item.url.includes('.pdf') ?
                                                            IcoPdf
                                                            :
                                                            IcoExcel
                                                } alt="" />  </ContianerItem>
                                        </a>
                                    </Col>
                                )
                            })}
                        </Row>

                    </Container>
                </Container>
            </ContainerGeneral>
        </Layout>
    )
}

export default Category