import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../src/fonts/style.css';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { onServiceWorkerUpdate } from '@3m1/service-worker-updater';


ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// serviceWorkerRegistration.register({
// 	// onUpdate: async (registration) => {
// 	//   // Corremos este código si hay una nueva versión de nuestra app
// 	//   // Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
// 	//   if (registration && registration.waiting) {
// 	//     await registration.unregister();
// 	//     registration.waiting.postMessage({ type: "SKIP_WAITING" });
// 	//     // Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
// 	//     window.location.reload();
// 	//   }
// 	// },
// 	onUpdate: registration => {
// 		alert('New version available!  Ready to update?');
// 		if (registration && registration.waiting) {
// 			registration.waiting.postMessage({ type: 'SKIP_WAITING' });
// 		}
// 		window.location.reload();
// 	}
// });
// There are some naming changes in newer Create React App versions
serviceWorkerRegistration.register({
	onUpdate: onServiceWorkerUpdate
});