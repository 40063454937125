import React, { useState, useEffect } from 'react'
import Layout from '../../layout'
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// images
import UserIcon from '../../../images/login/user.png'
import ShadowBottom from '../../../images/login/shadow-bottom.png'
// bootstrap
import { Col, Row, Container, Form, Button, Spinner } from 'react-bootstrap'
//buton
import { Primary } from '../../../components/general/Buttons/Primary'
import { useNavigate } from 'react-router-dom'
import clienteAxios from '../../../config/Axios'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateMeAction } from '../../../redux/actions/UpdateMe'
import Swal from 'sweetalert2'
import { ChangePasswordAction } from '../../../redux/actions/ChangePassword'

const ContainerGeneral = styled.div`
   padding-bottom:6rem;
`
const Section1 = styled.div`
    background: #2E2961;
`
const Section2 = styled.div`
    background: #fff;
`
const Edit = styled.p`
    font-family: 'Metropolis Extra Light';
    font-size: 1rem;
    color: #A3CDB7;
`
const Title = styled.p`
    font-family: 'Metropolis Extra Light';
    font-size: 1.25rem;
    color: #FFFFFF;
`
const User = styled.p`
    family: 'Metropolis Extra Light';
    font-size: 1.25rem;
    color: #A3CDB7;
`
const Card = styled.div`
    background: #F2F2F2;
    border-radius: 27px;
`
const Profile = () => {

    let token = useSelector(state => state.auth.token)
    let user = useSelector(state => state.auth)

    const navigate = useNavigate()
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const UpdateMeFn = (formdata, navigate, token) => dispatch(UpdateMeAction(formdata, navigate, token));
    const ChangePasswordFn = (formdata, navigate, token) => dispatch(ChangePasswordAction(formdata, navigate, token));

    let loading = useSelector(state => state.updateMe.loading);
    let loading2 = useSelector(state => state.changePassword.loading);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            formDataFn()
        }
    };



    const [userData, setUserData] = useState(null);

    const [formdata, setFormdata] = useState({
        firstname: "",
        lastname: "",
        phone: "",
        dni: "",
        creditIDId: "",
        creditID: "",
    });

    const readForm = (e) => {
        setFormdata({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }
    useEffect(() => {
        clienteAxios.get('/api/users/obtain-user/me', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                console.log(res.data)
                setFormdata({
                    firstname: res.data.firstname,
                    lastname: res.data.lastname,
                    phone: res.data.phone,
                    dni: res.data.dni,
                    creditID: res.data.creditIDId?.code
                })
                setUserData(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])



    const formDataFn = () => {
        console.log(formdata)
        let body = {
            firstname: formdata.firstname,
            lastname: formdata.lastname,
            phone: formdata.phone,
        }
        if (formdata.creditIDId !== "") {
            body.creditIDId = formdata.creditIDId
        }
        UpdateMeFn(body, navigate, token)
    }


    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")



    const formDataPasswordFn = (e) => {
        e.preventDefault()
        if (currentPassword === "") {
            Swal.fire({ icon: 'error', title: "Opps!", text: 'Ingrese su contraseña actual' })
        } else if (newPassword === "") {
            Swal.fire({ icon: 'error', title: "Opps!", text: 'Ingrese su nueva contraseña' })
        } else if (confirmNewPassword === "") {
            Swal.fire({ icon: 'error', title: "Opps!", text: 'Ingrese la confirmación de su nueva contraseña' })
        } else if (newPassword !== confirmNewPassword) {
            Swal.fire({ icon: 'error', title: "Opps!", text: 'Las contraseñas no coinciden' })
        } else {

            let body = {
                currentPassword,
                newPassword,
                confirmNewPassword,
            }

            ChangePasswordFn(body, navigate, token)

        }
    }


    const validateId = (e) => {
        e.preventDefault()
        console.log(e.target.value)

        if (e.target.value !== "") {
            clienteAxios.post(`/api/credit-ids/verify-credit-id`, { code: e.target.value })
                .then(res => {
                    console.log(res.data)
                    setFormdata({
                        ...formdata,
                        creditIDId: res.data._id,
                        creditID: res.data.code
                    })
                })
                .catch(err => {
                    Swal.fire({ title: 'Error', text: err.response.data[0].msg, icon: 'error' })
                    console.log(err.response)
                    setFormdata({
                        ...formdata,
                        creditIDId: "",
                        creditID: ""
                    })
                })
        }
    }

    return (
        <Layout back titlePage={"Perfil | NITRO+"}>
            <ContainerGeneral>
                <Section1>
                    <Container>
                        <Row>
                            <Col xs={12} className="text-center">
                                <img src={UserIcon} alt="user" />
                            </Col>
                            <Col xs={12} className="text-center my-2">
                                <User>{userData ? userData.firstname.toUpperCase() + " " + userData.lastname.toUpperCase() : "USUARIO"}</User>
                            </Col>
                            <Col xs={12}>
                                <Container>
                                    <Row>
                                        {user.creditIDId &&
                                            <Primary onClick={() => navigate('/perfil/mensajes')} padding="5px 10px" size="1rem" as={Col} xs={12} variant="secondary" className='my-2 text-center' css={css`border-radius:20px;`}>Mensajes</Primary>
                                        }
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Section1>
                <Section2>
                    <Container>
                        <Row>
                            <Col xs={12} className="text-center">
                                <Card className='p-3 mt-3'>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row className="my-3">
                                            <h3>Datos del usuario</h3>
                                            <Form.Group as={Col} xs="12" controlId="validationCustom01" className="d-flex flex-column justify-content-start text-start mb-2">
                                                <Form.Label>Nombre</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    autoComplete="off"
                                                    name="firstname"
                                                    value={formdata.firstname}
                                                    onChange={readForm}
                                                />
                                                <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} xs="12" controlId="validationCustom02" className="d-flex flex-column justify-content-start text-start mb-2">
                                                <Form.Label>Apellido</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    autoComplete="off"
                                                    name="lastname"
                                                    value={formdata.lastname}
                                                    onChange={readForm}
                                                />
                                                <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} xs="12" controlId="validationCustom03" className="d-flex flex-column justify-content-start text-start mb-2">
                                                <Form.Label>Teléfono</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    // placeholder='+549XXXXXXXXXX'
                                                    autoComplete="off"
                                                    name="phone"
                                                    value={formdata.phone}
                                                    onChange={readForm}
                                                />
                                                <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} xs="12" controlId="validationCustom03" className="d-flex flex-column justify-content-start text-start mb-2">
                                                <Form.Label>ID de Credito</Form.Label>
                                                <Form.Control
                                                    // required
                                                    type="text"
                                                    autoComplete="off"
                                                    name="creditID"
                                                    value={formdata.creditID}
                                                    onChange={readForm}
                                                    onBlur={validateId}
                                                />
                                                <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} xs="12" controlId="validationCustom04" className="d-flex flex-column justify-content-start text-start mb-4">
                                                <Form.Label>DNI</Form.Label>
                                                <Form.Control
                                                    disabled
                                                    type="number"
                                                    autoComplete="off"
                                                    name="dni"
                                                    value={formdata.dni}
                                                />
                                                <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} xs="12" className="mt-4">
                                                <Primary className='w-100' disabled={loading}>
                                                    {loading &&
                                                        <>
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className='me-2'
                                                            />
                                                            <span className="visually-hidden">Loading...</span>
                                                        </>
                                                    }
                                                    Actualizar datos</Primary>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </Card>
                                <Card className='p-3 my-3'>
                                    <h3>Cambio de contraseña</h3>
                                    <Form onSubmit={formDataPasswordFn}>
                                        <Form.Group as={Col} xs="12" controlId="validationCustom05" className="d-flex flex-column justify-content-start text-start mb-2">
                                            <Form.Label>Actual contraseña</Form.Label>
                                            <Form.Control
                                                type="password"
                                                autoComplete="off"
                                                name="currentPassword"
                                                onChange={(e) => setCurrentPassword(e.target.value)}
                                            />
                                            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs="12" controlId="validationCustom06" className="d-flex flex-column justify-content-start text-start mb-2">
                                            <Form.Label>Nueva contraseña</Form.Label>
                                            <Form.Control
                                                type="password"
                                                autoComplete="off"
                                                name="newPassword"
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs="12" controlId="validationCustom06" className="d-flex flex-column justify-content-start text-start mb-2">
                                            <Form.Label>Confirmar contraseña</Form.Label>
                                            <Form.Control
                                                type="password"
                                                autoComplete="off"
                                                name="confirmNewPassword"
                                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                            />
                                            <Form.Control.Feedback>Se ve bien!</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs="12" className="mt-4">
                                            <Primary type='submit' className='w-100' disabled={loading2}>
                                                {loading2 &&
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className='me-2'
                                                        />
                                                        <span className="visually-hidden">Loading...</span>
                                                    </>
                                                }Actualizar contraseña</Primary>
                                        </Form.Group>
                                    </Form>
                                </Card>
                            </Col>
                            <Col xs={12} className="mt-4">
                                <h3 className='text-center'>Sobre NITRO<sup>+</sup> Microcréditos</h3>
                            </Col>
                            <Row className="mx-0">
                                <Button as={Col} xs={12} variant="secondary" className='mt-2' css={css`border-radius:20px;`}>Términos y condiciones del uso de la webapp</Button>
                                {/* <Button as={Col} xs={12} variant="secondary" className='mt-2' css={css`border-radius:20px;`}>Términos y condiciones de NITRO<sup>+</sup> Pay</Button> */}
                                <Button as={Col} xs={12} variant="secondary" className='mt-2' css={css`border-radius:20px;`}>Política de Privacidad</Button>
                                <a href="https://api.whatsapp.com/send?phone=5493874111100&text=Hola NITRO+" target="_blank" className='mt-2 btn btn-secondary' css={css`border-radius:20px;`}>Contacto NITRO<sup>+</sup></a>
                                <a href="https://wa.me/?text=Mira la app de NITRO+ https://fio-plus.netlify.app/" target="_blank" className='mt-2 btn btn-secondary' css={css`border-radius:20px;`}>Compartir NITRO<sup>+</sup>
                                    <span className='ms-2'>
                                        <svg width={"20"} css={css`fill:#fff;`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M448 127.1C448 181 405 223.1 352 223.1C326.1 223.1 302.6 213.8 285.4 197.1L191.3 244.1C191.8 248 191.1 251.1 191.1 256C191.1 260 191.8 263.1 191.3 267.9L285.4 314.9C302.6 298.2 326.1 288 352 288C405 288 448 330.1 448 384C448 437 405 480 352 480C298.1 480 256 437 256 384C256 379.1 256.2 376 256.7 372.1L162.6 325.1C145.4 341.8 121.9 352 96 352C42.98 352 0 309 0 256C0 202.1 42.98 160 96 160C121.9 160 145.4 170.2 162.6 186.9L256.7 139.9C256.2 135.1 256 132 256 128C256 74.98 298.1 32 352 32C405 32 448 74.98 448 128L448 127.1zM95.1 287.1C113.7 287.1 127.1 273.7 127.1 255.1C127.1 238.3 113.7 223.1 95.1 223.1C78.33 223.1 63.1 238.3 63.1 255.1C63.1 273.7 78.33 287.1 95.1 287.1zM352 95.1C334.3 95.1 320 110.3 320 127.1C320 145.7 334.3 159.1 352 159.1C369.7 159.1 384 145.7 384 127.1C384 110.3 369.7 95.1 352 95.1zM352 416C369.7 416 384 401.7 384 384C384 366.3 369.7 352 352 352C334.3 352 320 366.3 320 384C320 401.7 334.3 416 352 416z" />
                                        </svg>
                                    </span>
                                </a>

                            </Row>
                        </Row>
                    </Container>
                </Section2>
            </ContainerGeneral>
        </Layout >

    )
}

export default Profile