import React, { createContext, useState } from 'react';

const IsLoguedInContext = createContext();
export default IsLoguedInContext;

export function IsLoguedInProvider({ children }) {
    const [isloguedIn, setIsloguedIn] = useState(false);

    return (
        <IsLoguedInContext.Provider
            value={{
                isloguedIn,
                setIsloguedIn
            }}
        >
            {children}
        </IsLoguedInContext.Provider>
    );
}


