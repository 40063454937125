import React, { useState, useRef } from 'react'
// bootstrap
import { Navbar, Container, Popover, Overlay, ListGroup } from 'react-bootstrap'
import UserIcon from '../../../images/general/user.png'
import UrrowBack from '../../../images/general/arrowBack.png'
// style
import styled from '@emotion/styled'
/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react'
// router
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { LogoutAction } from '../../../redux/actions/Auth'


const ContainerNavbar = styled.div`
	background:#2E2961 ;
	max-height: 60px;
    height: 60px;
`
const NameUser = styled.p`
	font-size: 1rem;
	text-transform:uppercase;
	margin-bottom:0;
`
const Logout = styled.p`
	font-size: 1rem;
	margin-bottom:0;
`

const Header = ({ back }) => {

	let user = useSelector(state => state.auth)
	let token = useSelector(state => state.auth.token)
	const navigate = useNavigate()
	//DISPATCH
	const dispatch = useDispatch();
	//ACTIONS
	const logout = (navigate, token) => dispatch(LogoutAction(navigate, token));

	let { pathname } = useLocation();

	const [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);

	const handleClick = (event) => {
		setShow(!show);
		setTarget(event.target);
	};

	function Login() {
		navigate('/login');
	}
	function Register() {
		navigate('/registro');
	}

	const logoutFn = () => {
		logout(navigate, token)
	}


	return (
		<ContainerNavbar className=''>
			<Navbar bg="transparent" variant="dark">
				<Container>
					<Navbar.Brand className='d-flex align-items-center justify-content-between w-100'>
						<div className='d-flex align-items-center'>
							{back &&
								<img
									onClick={() => navigate(-1)}
									alt="user"
									src={UrrowBack}
									width="30"
									height="30"
									className="d-inline-block align-top me-3"
									css={css`cursor:pointer;`}
								/>
							}
							<img
								onClick={() => navigate("/perfil")}
								alt="user"
								src={UserIcon}
								width="35"
								height="35"
								className="d-inline-block align-top me-3 cursor-pointer"
							/>
							<NameUser>
								{user ? user.firstname?.toUpperCase() : "USUARIO"}
							</NameUser>
						</div>

						{token ?
							<div css={css`cursor:pointer;`} className='d-flex align-items-center' onClick={() => logoutFn()}>
								<svg fill="#fff" width={'25'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<path d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z" />
								</svg>
								<Logout className='ms-2'>
									Salir
								</Logout>
							</div>
							:
							<div ref={ref}>
								<svg onClick={handleClick} xmlns="http://www.w3.org/2000/svg" css={css`cursor:pointer;fill:#fff;`} width={'25'} viewBox="0 0 448 512">
									<path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z" />
								</svg>
								<Overlay
									show={show}
									target={target}
									placement="bottom"
									container={ref}
									containerPadding={20}
								>
									<Popover id="popover-contained">
										<ListGroup variant="flush" css={css`border-radius:20px;`}>
											<ListGroup.Item action onClick={Login} className="d-flex align-items-center">
												<span className='me-2'>
													<svg css={css`cursor:pointer;fill:#000;`} width={'16'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
														<path d="M416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32zM342.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L242.8 224H32C14.31 224 0 238.3 0 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C355.1 266.1 355.1 245.9 342.6 233.4z" />
													</svg>
												</span>Iniciar sesión
											</ListGroup.Item>
											<ListGroup.Item action onClick={Register} className="d-flex align-items-center">
												<span className='me-2'>
													<svg css={css`cursor:pointer;fill:#000;`} width={'16'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
														<path d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3C0 496.5 15.52 512 34.66 512h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM616 200h-48v-48C568 138.8 557.3 128 544 128s-24 10.75-24 24v48h-48C458.8 200 448 210.8 448 224s10.75 24 24 24h48v48C520 309.3 530.8 320 544 320s24-10.75 24-24v-48h48C629.3 248 640 237.3 640 224S629.3 200 616 200z" />
													</svg>
												</span>
												Registrarse
											</ListGroup.Item>
										</ListGroup>
									</Popover>
								</Overlay>
							</div>

						}
					</Navbar.Brand>
				</Container>
			</Navbar>
		</ContainerNavbar >
	)
}

export default Header